




















import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

const MOLECULES = {
  1: '$\\text{5-flurohex-2-ene}$',
  2: '$\\text{3,5-dimethylhex-3-en-2-ol}$',
  3: '$\\text{2-methylhex-4-en-3-ol}$',
};

export default defineComponent({
  name: 'TorontoCHMB41HQuiz3Question4',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
  },
  data() {
    return {
      isomerAttachments: [] as File[],
    };
  },
  computed: {
    version(): number {
      return this.taskState.getValueByName('version')?.content?.value ?? 1;
    },
    molecule(): string {
      return MOLECULES[this.version as keyof typeof MOLECULES];
    },
    attachments(): File[] {
      return [...this.isomerAttachments];
    },
  },
});
